

import BodyWrapper from "../../components/layouts/BodyWrapper";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import avatar from "../../assets/img/avatar/avatar-1.png";

const Body = () => {
    return (
        <div className="main-content">
            <section className="section">
                <div className="section-header">
                    <h1>Students Profile</h1>
                </div>

                <div className="section-body">
                    <h2 className="section-title">Abdulrasheed Ibraheem</h2>
                    {/* <p className="section-lead">You can edit your account from here</p> */}

                    <div className="row mt-sm-4">
                        <div className="col-12 col-md-12 col-lg-8">
                            <div className="card profile-widget">
                                <div className="profile-widget-header">                     
                                    <img alt="image" src={avatar} className="rounded-circle profile-widget-picture" width={130} />
                                    <div className="profile-widget-items">
                                        <div className="profile-widget-item">
                                            <div className="profile-widget-item-label">Challenges</div>
                                            <div className="profile-widget-item-value">7</div>
                                        </div>
                                        <div className="profile-widget-item">
                                            <div className="profile-widget-item-label">Projects</div>
                                            <div className="profile-widget-item-value">6</div>
                                        </div>
                                        <div className="profile-widget-item">
                                            <div className="profile-widget-item-label">Stars</div>
                                            <div className="profile-widget-item-value">225</div>
                                        </div>
                                        <div className="profile-widget-item">
                                            <div className="profile-widget-item-label">Position</div>
                                            <div className="profile-widget-item-value">21st</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-widget-description">
                                    <div className="profile-widget-name">Abdulrasheed Ibraheem <div className="text-muted d-inline font-weight-normal"><div className="slash"></div>Frontend Student</div></div>
                                    I am a web development enthusiast from <b>Nigeria</b> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident, harum. Beatae veritatis quasi aspernatur obcaecati voluptatum neque eaque tempore. Assumenda vero neque dicta alias quidem quisquam necessitatibus, delectus voluptates ab.<b>'John Doe'</b>.
                                </div>

                                <div className="profile-widget-description">
                                    <span class="badge badge-primary font-weight-bold"><i className="fab fa-whatsapp"></i> +2348126430670</span>
                                    &nbsp; &nbsp;
                                    <span class="badge badge-secondary font-weight-bold"><i className="fas fa-envelope"></i> arib@gmail.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const StudentProfile = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default StudentProfile;