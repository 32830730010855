import { Link } from "react-router-dom";


// const Body = () => {
//     return (
//     )
// }

const ErrorPage = () => {
    return (
        
        <section class="section">
            
            <div class="navbar-bg"></div>
            <div class="container mt-5">
                <div class="page-error">
                    <div class="page-inner">
                        <h1>404</h1>
                        <div class="page-description">
                            The page you were looking for could not be found.
                        </div>
                        <div class="page-search">
                            <form>
                                <div class="form-group floating-addon floating-addon-not-append">
                                   
                                </div>
                            </form>
                            <div class="mt-3"><Link to="/">Back to Home</Link></div>
                        </div>
                    </div>
                </div>
                <div class="simple-footer mt-5">Copyright &copy; Geegstack Academy 2022</div>
            </div>
        </section>
    )
}

export default ErrorPage;