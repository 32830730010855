

import { Link, useNavigate } from "react-router-dom";
import BodyWrapper from "../../components/layouts/BodyWrapper";
// import threadImg from "../../assets/img/news/img12.jpg";
import { useEffect, useState } from "react";
import { Course, Project, serverurl } from "../../api";
import Toast from "../../components/Toast";


const Body = () => {
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const [technologies, setTechnologies] = useState([]);
    const [currentTech, setCurrentTech] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Course.getStudentTechnologies().then((res) => {
            if(res.type !== "error") {
                setTechnologies(res.technologies);
                setCurrentTech(res.technologies[0].name);
                return getTechProjects(res.technologies[0].name);
            }
            Toast.fire({icon: "error", title: res.message})
            .then(() => {
                navigate("/");
            })

        })
        .catch(() => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"})
            .then(() => {
                navigate("/");
            })
        })

    }, [])

    const getTechProjects = (name) => {
        setLoading(true);
        Project.getTechProjects({tech: name})
        .then((res) => {
            setCurrentTech(name);
            setLoading(false);
            if(res.type !== "error") {
                return setProjects(res.projects);
            }
            Toast.fire({icon: "error", title: res.message})
            .then(() => {
                navigate("/");
            })
        })
        .catch(() => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"})
            .then(() => {
                navigate("/");
            })
        })
    }

    return (
        <div class="main-content">
            <section className="section">
                    <Link to={-1} className="goback"><span className="fa fa-arrow-left"></span> Go Back</Link>
                <div class="section-header">
                    <h1>Projects</h1>
                </div>

                <div class="section-body">
                    <h2 class="section-title">Are you ready?</h2>
                    <p class="section-lead">Remember the only way to be a developer is by developing!</p>

                    
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <ul class="nav nav-pills" id="myTab3" role="tablist">
                                        {
                                            technologies && technologies.map((tech, id) => {
                                                return (
                                                    <li class="nav-item" key={id}>
                                                         <a class="nav-link" id="profile-tab3" data-toggle="tab" href="#home3" role="tab" aria-controls="profile" aria-selected="false" onClick={() => getTechProjects(tech.name)}>
                                                            <b>{tech.name}</b>
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }

                                    </ul>
                                </div>
                                <div class="card-body bg-light">
                                    <div class="tab-content" id="myTabContent2">
                                        <div class="tab-pane fade show active" id="home3" role="tabpanel" aria-labelledby="home-tab3">
                                            <div class="row" style={{minHeight: "350px"}}>
                                                {
                                                    projects && projects.length > 0 ?
                                                    projects.map(project => {
                                                        return (
                                                            <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                                                                <article class="article article-style-b">
                                                                    <div class="article-header">
                                                                        <div class="article-image">
                                                                            <img src={project.picture_url && serverurl + project.picture_url} width="100%" alt="" />
                                                                        </div>
                                                                        <div class="article-badge">
                                                                            <span class="badge badge-danger font-weight-bold">{project.technologies}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="article-details">
                                                                        <div class="article-title">
                                                                            <h6>{project.title}</h6>
                                                                        </div>
                                                                        <p>{project.description}</p>
                                                                        <div class="article-cta">
                                                                            <Link to={"/projects/" + project.id}>Start Project<i class="fas fa-chevron-right"></i></Link>
                                                                        </div>
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    loading ?
                                                    <p className="col-12 col-sm-6 col-md-6">Fetching technology projects...</p>
                                                    :
                                                    <p className="col-12 col-sm-6 col-md-6">No project has been added for {currentTech} yet.</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const Projects = () => {
    return (
        <BodyWrapper children={<Body />} page={"project"} />
    )
}

export default Projects;