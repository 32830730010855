

import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Lecture, serverurl } from "../../api";
import BodyWrapper from "../../components/layouts/BodyWrapper";
import Toast from "../../components/Toast";
import "../../assets/css/video.css"
import Swal from "sweetalert2";

const Body = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [lecture, setLecture] = useState({});
    const lectureID = location.pathname.split("/")[2];
    const [lectureFile, setLectureFile] = useState({});
    const [lectureQuestion, setLectureQuestion] = useState("");
    const [questions, setQuestions] = useState([]);

    const handleFile = e => {
        setLectureFile(e.target.files[0]);
    }

    useEffect(() => {
        //Check student's lecture watchability
        Lecture.lectureWatchability({lectureID}).then(res => {
            if(res.type === "error") {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/lectures"));
            }

            //get lecture details
            Lecture.lectureDetails({lectureID: lectureID}).then(res => {
                if(res.type === "error") {
                    return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/"));
                }
                setLecture(res.lecture);
            }).catch(err => {
                return Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/"));
            })

            Lecture.getLectureQuestions({lectureID}).then(res => {
                if(res.type === "error") {
                    return Toast.fire({icon: "error", title: res.message});
                }
                // console.log(res);
                setQuestions(res.questions);
            }).catch((err) => console.log(err))

        }).catch(err => {
            Toast.fire({icon: "error", title: "Lecture error: Check your network connection and reload the page. If error persists, contact an Instructor immediately."}).then(() => navigate("/lectures"));
        })

    }, [])

    const markLectureComplete = e => {
        e.preventDefault();

        const form = new FormData();
        form.append("lectureID", lectureID);
        form.append("submissionFile", lectureFile);
        Lecture.markLectureCompleted(form).then(res => {
            if(res.type === "error") {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success",title: "Lecture file uploaded and marked completed. You can now proceed with next lecture."}).then(() => navigate("/lectures"));
        }).catch(() => {
            Toast.fire({icon: "error", title: "Network error"});
        })
    }

    const askQuestion = (e) => {
        e.preventDefault();
        Lecture.askQuestion({lecture: lectureID, question: lectureQuestion}).then(res => {
            if(res.type === "error") {
                return Toast.fire({icon: "error", title: res.message});
            }
            setLectureQuestion("");
            Toast.fire({icon: "success", title: "Question submitted successfully. You will know when it's answered."}).then(() => {

            });
        }).catch(() => {
            Toast.fire({icon: "error", title: "Network error while sending question."});
        })
    }

    return (
        <div className="main-content">
            <section className="section">
                <Link to={-1} className="goback"><span className="fa fa-arrow-left"></span> Go Back</Link>
                <div className="section-header">
                    <h1>Lecture Module</h1>
                </div>

                <div className="section-body">
                    <h2 className="section-title">{lecture ? lecture.title : "Loading.."}</h2>
                    <p className="section-lead">{lecture ? lecture.name : "Loading.."}</p>

                    
                    <div className="row">
                        <div className="col-12">
                            
                        <div className="col-md-10">
                            <div className="card" id="settings-card">
                                <div className="card-header">
                                    <h4>Pro Tip: Pause the video to follow-up at 3-5minutes interval</h4>
                                </div>
                                <div className="card-body">
                                    {/* <div className="form-group row align-items-center">
                                        {lecture?.url && <iframe width="100%" height="445" src={ lecture && lecture.url + "?rel=0" } title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="on" rel="0"></iframe>}
                                    </div> */}

                                    <div className="form-group flex row align-items-center justify-content-center">
                                        
                                        {
                                            lecture.url &&
                                        <video id="videoPlayer"  width="95%" height="445" controls autoplay>
                                            <source src={ serverurl + "/lectures/streamVideo/" + lectureID} type={"video/" + lecture.url.split(".")[1]} />
                                        </video>
                                         }
                                    </div>

                                    <br />
                                    <div className="form-group row ">
                                        <h6 htmlFor="site-description" className="form-control-p col-sm-3 text-md-right">Lecture Notes:</h6>
                                        <div className="col-sm-6 col-md-9">
                                            <p>{lecture ? lecture.notes : "Loading.."}</p>
                                        </div>
                                    </div>

                                    <div className="form-group row ">
                                        <h6 className="form-control-p col-sm-3 text-md-right">Lecture file:</h6>
                                        <div className="col-sm-6 col-md-9">
                                            <p>Submit the file you used to follow up the lecture and mark it as complete to proceed with the next lecture. You'll only be able to proceed to next technology if this is approved.</p>
                                            <div className="form-group">
                                                <input type="file" className="form-control" onChange={handleFile} />
                                            </div>
                                        </div>
                                    </div>
                                        <div className="card-footer text-md-right">
                                            <button className="btn btn-primary" id="save-btn" onClick={markLectureComplete}>Mark as completed</button>
                                        </div>
                                </div>
                            </div>

                            
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Questions and Answers on Lecture</h4>
                                </div>
                                <div className="card-body">
                                    {/* <div id="accordion">
                                        <div className="accordion">
                                            <div className="accordion-header" role="button" data-toggle="collapse" data-target="#panel-body-1" aria-expanded="true">
                                                <h4 style={{lineHeight: "2"}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima minus labore modi cumque, tempora porro ipsum, libero harum sequi nobis optio similique facilis sint vel explicabo dolor provident dignissimos fuga!</h4>
                                            </div>
                                            <div className="accordion-body collapse show" id="panel-body-1" data-parent="#accordion">
                                                <p className="mb-0">
                                                <b>Answer: </b>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                            </div>
                                        </div>
                                        <div className="accordion">
                                            <div className="accordion-header" role="button" data-toggle="collapse" data-target="#panel-body-2" >
                                                <h4 style={{lineHeight: "2"}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima minus labore modi cumque, tempora porro ipsum, libero harum sequi nobis optio similique facilis sint vel explicabo dolor provident dignissimos fuga!</h4>
                                            </div>
                                            <div className="accordion-body collapse " id="panel-body-2" data-parent="#accordion">
                                                <p className="mb-0">
                                                <b>Answer: </b>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                            </div>
                                        </div>
                                    </div> */}

                                    <p>No question has been asked yet.</p>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h4>Ask a question anonymously</h4>
                                </div>
                                <div className="card-body">
                                    <div className="form-group">
                                        <textarea 
                                        className="form-control" 
                                        cols={200} rows={100} 
                                        value={lectureQuestion}
                                        onChange={e => setLectureQuestion(e.target.value)}
                                        placeholder="Type in here.."
                                        ></textarea>
                                    </div>
                                    <div className="card-footer text-md-right">
                                        <button className="btn btn-primary" onClick={askQuestion}>Ask question</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                    
                </div>
            </section>
        </div>
    )
}

const Module = () => {
    return (
        <BodyWrapper children={<Body />}  page={"lecture"} />
    )
}

export default Module;