
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import avatar from "../../assets/img/avatar/default.png";
import Cookies from "js-cookie";
import { General, serverurl } from "../../api";
import Toast from "../Toast";
import { useState } from "react";

const TopNavbar = () => {
    const navigate = useNavigate();
    const [student, setStudent] = useState({});

    useEffect( () => {
        const student = Cookies.get("gs_student");
        if(!student || student.length < 1) {
            navigate("/login");
        }

        General.getNavData().then(res => {
            if(res.type === "error") {
                Toast.fire({icon: "error", title: res.message}).then(() => {
                    Cookies.remove("gs_student");
                    navigate("/login");
                })
            }
            setStudent(res.student);
        }).catch(() => {
            Toast.fire({icon: "error", title: "Network error"}).then(() => {
                Cookies.remove("gs_student");
                navigate("/login");
            })
        })

    }, [])

    const Logout = () => {
        Cookies.remove("gs_student");
        navigate("/login");
    }

    return (
        <nav className="navbar navbar-expand-lg main-navbar">
            <form className="form-inline mr-auto">
                <ul className="navbar-nav mr-3">
                    <li><a href="#" data-toggle="sidebar" className="nav-link nav-link-lg"><i className="fas fa-bars"></i></a></li>
                    <li><a href="#" data-toggle="search" className="nav-link nav-link-lg d-sm-none"><i className="fas fa-search"></i></a></li>
                </ul>
            </form>

            <ul className="navbar-nav navbar-right">
                
                {/* <li className="dropdown dropdown-list-toggle"><a href="#" data-toggle="dropdown" className="nav-link notification-toggle nav-link-lg beep"><i className="far fa-bell"></i></a>
                    <div className="dropdown-menu dropdown-list dropdown-menu-right">
                    <div className="dropdown-header">Notifications
                        <div className="float-right">
                            <a href="#">Mark All As Read</a>
                        </div>
                    </div>
                    <div className="dropdown-list-content dropdown-list-icons">
                        <a href="#" className="dropdown-item dropdown-item-unread">
                            <div className="dropdown-item-icon bg-primary text-white">
                                <i className="fas fa-code"></i>
                            </div>
                            <div className="dropdown-item-desc"> Template update is available now!
                                <div className="time text-primary">2 Min Ago</div>
                            </div>
                        </a>
                        <a href="#" className="dropdown-item">
                            <div className="dropdown-item-icon bg-info text-white">
                                <i className="far fa-user"></i>
                            </div>
                            <div className="dropdown-item-desc">
                                <b>You</b> and <b>Dedik Sugiharto</b> are now friends
                                <div className="time">10 Hours Ago</div>
                            </div>
                        </a>
                        <a href="#" className="dropdown-item">
                            <div className="dropdown-item-icon bg-success text-white">
                                <i className="fas fa-check"></i>
                            </div>
                            <div className="dropdown-item-desc">
                                <b>Kusnaedi</b> has moved task <b>Fix bug header</b> to <b>Done</b>
                                <div className="time">12 Hours Ago</div>
                            </div>
                        </a>
                        <a href="#" className="dropdown-item">
                            <div className="dropdown-item-icon bg-danger text-white">
                                <i className="fas fa-exclamation-triangle"></i>
                            </div>
                            <div className="dropdown-item-desc">
                                Low disk space. Let's clean it!
                                <div className="time">17 Hours Ago</div>
                            </div>
                        </a>
                        <a href="#" className="dropdown-item">
                            <div className="dropdown-item-icon bg-info text-white">
                                <i className="fas fa-bell"></i>
                            </div>
                            <div className="dropdown-item-desc">
                                Welcome to CodiePie template!
                                <div className="time">Yesterday</div>
                            </div>
                        </a>
                    </div>
                    <div className="dropdown-footer text-center">
                        <a href="#">View All <i className="fas fa-chevron-right"></i></a>
                    </div>
                    </div>
                </li> */}

                <li className="dropdown">
                    <a href="#" data-toggle="dropdown" className="nav-link dropdown-toggle nav-link-lg nav-link-user">
                    <img alt="image" src={student.avatar_url ? serverurl + "/profile_pics/" + student.avatar_url :  avatar} className="mr-1" style={{objectFit: "cover", borderRadius: "50%"}} width={"50px"} height={"30px"} />

                    <div className="d-sm-none d-lg-inline-block">Hi, {student.fname}</div></a>
                    <div className="dropdown-menu dropdown-menu-right">
                        {/* <div className="dropdown-title">Logged in 5 min ago</div> */}
                        <Link to="/profile" className="dropdown-item has-icon"><i className="far fa-user"></i> Profile</Link>
                        <div className="dropdown-divider"></div>
                        <a href="#" className="dropdown-item has-icon text-danger" onClick={Logout}><i className="fas fa-sign-out-alt"></i> Logout</a>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default TopNavbar;