
import { Link, useNavigate } from "react-router-dom";
import BodyWrapper from "../../components/layouts/BodyWrapper";
import { useEffect, useState } from "react";
import { Challenge, Course, serverurl } from "../../api";
import Toast from "../../components/Toast";

const Body = () => {
    
    const navigate = useNavigate();
    const [challenges, setChallenges] = useState([]);
    const [technologies, setTechnologies] = useState([]);
    const [currentTech, setCurrentTech] = useState("");
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        Course.getStudentTechnologies().then((res) => {
            if(res.type !== "error") {
                setTechnologies(res.technologies);
                setCurrentTech(res.technologies[0].name);
                return getTechChallenges(res.technologies[0].name);
            }
            Toast.fire({icon: "error", title: res.message})
            .then(() => {
                navigate("/");
            })

        })
        .catch(() => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"})
            .then(() => {
                navigate("/");
            })
        })

    }, [])

    const getTechChallenges = (tech) => {
        setLoading(true);
        Challenge.getTechChallenges({challenge: tech})
        .then((res) => {
            setCurrentTech(tech);
            setLoading(false);
            if(res.type !== "error") {
                return setChallenges(res.challenges);
            }
            Toast.fire({icon: "error", title: res.message})
            .then(() => {
                navigate("/");
            })
        })
        .catch(() => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"})
            .then(() => {
                navigate("/");
            })
        })
    }

    return (
        <div class="main-content">
            <section className="section">
                <Link to={-1} className="goback"><span className="fa fa-arrow-left"></span> Go Back</Link>
                <div class="section-header">
                    <h1>Challenges</h1>
                </div>

                <div class="section-body">
                    <h2 class="section-title">Challenges</h2>
                    <p class="section-lead">The only way to become a developer is by developing!</p>

                    
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <ul class="nav nav-pills" id="myTab3" role="tablist">
                                    {
                                        technologies && technologies.map((tech, id) => {
                                            return (
                                                <li class="nav-item" key={id}>
                                                    <a class="nav-link" id="profile-tab3" data-toggle="tab" href="#home3" role="tab" aria-controls="profile" aria-selected="false" onClick={() => getTechChallenges(tech.name)}>
                                                        <b>{tech.name}</b>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                    </ul>
                                </div>
                                <div class="card-body bg-light">
                                    <div class="tab-content" id="myTabContent2">
                                        <div class="tab-pane fade show active" id="home3" role="tabpanel" aria-labelledby="home-tab3">
                                            <div class="row" style={{minHeight: "350px"}}>
                                                {
                                                    challenges && challenges.length > 0 ?
                                                    challenges.map(challenge => {
                                                        return (
                                                            <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                                                                <article class="article article-style-b">
                                                                    <div class="article-header">
                                                                        <div class="article-image">
                                                                            <img src={challenge.picture_url && serverurl + challenge.picture_url} width="100%" alt="" />
                                                                        </div>
                                                                        <div class="article-badge">
                                                                            <span class="badge badge-danger font-weight-bold">{challenge.technologies}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="article-details">
                                                                        <div class="article-title">
                                                                            <h6>{challenge.title}</h6>
                                                                        </div>
                                                                        <p>{challenge.description}</p>
                                                                        <div class="article-cta">
                                                                            <Link to={"/challenges/" + challenge.id}>Start Challenge<i class="fas fa-chevron-right"></i></Link>
                                                                        </div>
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    loading ?
                                                    <p className="col-12 col-sm-6 col-md-6">Fetching technology challenges...</p>
                                                    :
                                                    <p className="col-12 col-sm-6 col-md-6">No challenge has been added for {currentTech} yet.</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const Challenges = () => {
    return (
        <BodyWrapper children={<Body />} page="challenge" />
    )
}

export default Challenges;