

import BodyWrapper from "../../components/layouts/BodyWrapper";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import avatar from "../../assets/img/avatar/default.png";
import { Link, useNavigate } from "react-router-dom";
import { General, serverurl } from "../../api";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Toast from "../../components/Toast";

const Body = () => {
    const navigate = useNavigate();
    const [student, setStudent] = useState({});
    const [profileForm, setProfileForm] = useState({fname: "", lname: "", phone: "", bio: ""});
    const [tempImgUrl, setTempImgUrl] = useState("");
    const [tempImg, setTempImg] = useState({});

    useEffect(() => {
        General.profile()
        .then(res => {
            if (res.type === "error") {
                return Toast.fire({icon: "error", title: res.message})
                .then(() => {
                    navigate("/login");
                });
            }
            setStudent(res.student);
            setProfileForm({fname: res.student.fname, lname: res.student.lname, phone: res.student.phone, bio: res.student.bio})
        })
        .catch(err => {
            return Toast.fire({icon: "error", title: "Network error"})
            .then(() => {
                navigate("/login");
            });
        })

    }, [])

    const avatarChange = (e) => {
        setTempImg(e.target.files[0]);
        setTempImgUrl(URL.createObjectURL(e.target.files[0]));
    }

    const formChange = (e) => {
        setProfileForm({...profileForm, [e.target.name]: e.target.value});
    }

    const bioChange = (val) => {
        setProfileForm({...profileForm, bio: val})
    }

    const updateProfile = (e) => {
        e.preventDefault();

        const form = new FormData();

        for (const key in profileForm) {
            if (Object.hasOwnProperty.call(profileForm, key)) {
                const data = profileForm[key];
                form.append(key, data)
            }
        }
        form.append("avatar", tempImg);
        General.updateProfile(form)
        .then(res => {
            if (res.type === "error") {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "Profile updated successfully!"})
            General.profile()
            .then(res => {
                if (res.type === "error") return Toast.fire({icon: "error", title: res.message})
                setStudent(res.student);
                setProfileForm({fname: res.student.fname, lname: res.student.lname, phone: res.student.phone, bio: res.student.bio})
            })

        })
        .catch(() => {
            return Toast.fire({icon: "error", message: "Network error"});
        })
    }

    return (
        <div className="main-content">
            <section className="section">
                <Link to={-1} className="goback"><span className="fa fa-arrow-left"></span> Go Back</Link>
                <div className="section-header">
                    <h1>My Profile</h1>
                </div>

                <div className="section-body">
                    <h2 className="section-title">Hi, {student?.fname}!</h2>
                    <p className="section-lead">You can edit your account from here</p>

                    <div className="row mt-sm-4">
                        <div className="col-12 col-md-12 col-lg-5">
                            <div className="card profile-widget">
                                <div className="profile-widget-header">                     
                                    <img alt="image" src={student.avatar_url ? `${serverurl}/profile_pics/${student.avatar_url}` : avatar} className="rounded-circl profile-widget-picture" style={{objectFit: "cover", borderRadius: "50%"}} width={100} height={100} />

                                    <div className="profile-widget-items">
                                        <div className="profile-widget-item">
                                            <div className="profile-widget-item-label">Challenges</div>
                                            <div className="profile-widget-item-value">{student.challenges}</div>
                                        </div>
                                        <div className="profile-widget-item">
                                            <div className="profile-widget-item-label">Projects</div>
                                            <div className="profile-widget-item-value">{student.projects}</div>
                                        </div>
                                        <div className="profile-widget-item">
                                            <div className="profile-widget-item-label">Stars</div>
                                            <div className="profile-widget-item-value">{student.stars}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-widget-description">
                                    <div className="profile-widget-name">{student?.fname} {student?.lname} <div className="text-muted d-inline font-weight-normal"><div className="slash"></div>{student?.course?.split(" ")[0]} Student</div></div>
                                    <p dangerouslySetInnerHTML={{__html: student?.bio || `${student?.fname} is a ${student?.course} Student at Geegstack Academy.`}}></p>
                                    {/* {} */}
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-7">
                            <div className="card">
                                <form method="post" className="needs-validation" novalidate="" onSubmit={updateProfile}>
                                    <div className="card-header">
                                        <h4>Edit Profile</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group col-md-6 col-12">
                                                <label>First Name</label>
                                                <input type="text" className="form-control" value={profileForm.fname} required="" name="fname" onChange={formChange} />
                                                <div className="invalid-feedback">Please fill in the first name</div>
                                            </div>
                                            <div className="form-group col-md-6 col-12">
                                                <label>Last Name</label>
                                                <input type="text" className="form-control" value={profileForm.lname} name="lname" onChange={formChange} required="" />
                                                <div className="invalid-feedback">Please fill in the last name</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-7 col-12">
                                                <label>Email</label>
                                                <input type="email" className="form-control" value={student.email} name="email" required readOnly />
                                                <div className="invalid-feedback">Please fill in the email</div>
                                            </div>
                                            <div className="form-group col-md-5 col-12">
                                                <label>Phone</label>
                                                <input type="tel" className="form-control" value={profileForm.phone} name="phone" onChange={formChange} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <label>Bio</label>
                                                <ReactQuill defaultValue={profileForm.bio} onChange={bioChange}></ReactQuill>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <label>Profile Picture</label>
                                                <div>
                                                    <img src={tempImgUrl || (student.avatar_url ? `${serverurl}/profile_pics/${student.avatar_url}` : avatar)} alt="" width={100}/>
                                                    <br/> <br/>
                                                    <input type="file" accept="image/*" onChange={avatarChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer text-right">
                                        <button className="btn btn-primary" onClick={updateProfile}>Save Changes</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const MyProfile = () => {
    return (
        <BodyWrapper children={<Body />} page={"dashboard"} />
    )
}

export default MyProfile;