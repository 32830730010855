import { Link } from "react-router-dom";

const Sidebar = ({page}) => {
    return (
        <div className="main-sidebar sidebar-style-2">
            <aside id="sidebar-wrapper">
                <div className="sidebar-brand">
                    <Link to="/">Geegstack Academy</Link>
                </div>
                <div className="sidebar-brand sidebar-brand-sm">
                    <a href="index-2.html">GSA</a>
                </div>
                <ul className="sidebar-menu">
                    <li className={page === "dashboard" && "active"}><Link className="nav-link" to="/"><i className="fas fa-home"></i> <span>Dashboard</span></Link></li>
                    <li className={page === "lecture" && "active"}><Link className="nav-link" to="/lectures"><i className="fas  fa-chalkboard-teacher"></i> <span>My Lectures</span></Link></li>
                    <li className={page === "challenge" && "active"}><Link className="nav-link" to="/challenges"><i className="fas fa-laptop-code"></i> <span>Challenges</span></Link></li>
                    <li  className={page === "project" && "active"}><Link className="nav-link" to="/projects"><i className="fas fa-project-diagram"></i> <span>Projects</span></Link></li>
                    <li className={page === "leaderboard" && "active"}><Link className="nav-link" to="/leaderboard"><i className="fas fa-fire"></i> <span>Leaderboard</span></Link></li>
                    <li className={page === "submissions" && "active"}><Link className="nav-link" to="/my-submissions"><i className="fas fa-upload"></i> <span>My Submissions</span></Link></li>
                    <li className={page === "quizzes" && "active"}><Link className="nav-link" to="/quizzes"><i className="fas fa-shapes"></i> <span>Quizzes</span></Link></li>
                    {/* <li><Link className="nav-link" to="/schedule"><i className="far fa-calendar"></i> <span>Schedule Meeting</span></Link></li> */}
                    {/* <li className="menu-header">Dashboard</li> */}
                    
                    {/* <li className="active"><a className="nav-link" href="credits.html"><i className="fas fa-pencil-ruler"></i> <span>Credits</span></a></li> */}
                </ul>
                <div className="mt-4 mb-4 p-3 hide-sidebar-mini">
                    <Link to="/course-details" className="btn btn-primary btn-lg btn-block">  <i className="fas fa-graduation-cap"></i> &nbsp; My Course Details</Link>
                </div>
            </aside>
        </div>
    )
}

export default Sidebar;