
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { General } from "../../api";
import BodyWrapper from "../../components/layouts/BodyWrapper";
import Toast from "../../components/Toast";
import Cookies from "js-cookie";

const Body = () => {
    const navigate = useNavigate();
    const [student, setStudent] = useState({});

    useEffect(() => {
        General.dashboardData().then(res => {
            if(res.type === "error") {
                Toast.fire({icon: "error", title: "Network error"}).then(() => {
                    Cookies.remove("gs_student");
                    navigate("/login");
                })
            }
            setStudent({...res.student, lectures: res.lectures})
        }).catch(err => {
            Toast.fire({icon: "error", title: "Network error"}).then(() => {
                Cookies.remove("gs_student");
                navigate("/login");
            })
        })
    }, [])
    
    return (
        <div className="main-content">
            
            <section className="section">
                <div className="section-header">
                    <h1>Dasboard</h1>
                </div>

                <div className="section-body">
                    <h2 className="section-title">Hi, Welcome {student.fname}!</h2>
                    <p className="section-lead">What would you like to do now?</p>

                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="card card-large-icons">
                                        <div className="card-icon bg-primary text-white"><i className="fas fa-chalkboard-teacher"></i></div>
                                        <div className="card-body">
                                            <h4>Continue Learning</h4>
                                            <p style={{fontWeight: "600"}}>Proceed with your lectures. You're currently in the {student.lectures && student.lectures[0]?.name} module and there are {student.currently_learning == 12 ? 2 : (student.lectures?.length - (student.lectures?.find(lect => lect.id == student.last_lecture )?.lecture_order) )} more videos to go.</p>
                                            <Link to="/lectures" className="card-cta">Continue Learning <i className="fas fa-chevron-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card card-large-icons">
                                        <div className="card-icon bg-primary text-white"><i className="fas fa-laptop-code"></i></div>
                                        <div className="card-body">
                                            <h4>Enter new challenge</h4>
                                            <p>Take a new challenge on your path to get more stars and stand a chance to win Geegstack Perks.</p>
                                            <Link to="/challenges" className="card-cta">Enter Challenge<i className="fas fa-chevron-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card card-large-icons">
                                        <div className="card-icon bg-primary text-white"><i className="fas fa-users"></i></div>
                                        <div className="card-body">
                                            <h4>Go to Forum</h4>
                                            <p>Visit the academy forum to join discussion or ask a question and get help from co-learners.</p>
                                            <a href="https://geegstackacademy.slack.com" target="_blank" className="card-cta">Visit Forum<i className="fas fa-chevron-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card card-large-icons">
                                        <div className="card-icon bg-primary text-white"><i className="fas fa-shapes"></i></div>
                                        <div className="card-body">
                                            <h4>Take a Quiz</h4>
                                            <p>Test your knowledge with Quizzes. Get more stars and stand a chance to win Geegstack Perks.</p>
                                            <Link to="/quizzes" className="card-cta">Take Quiz<i className="fas fa-chevron-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const Dashboard = () => {
    return (
        <BodyWrapper children={<Body />} page={"dashboard"} />
    )
}

export default Dashboard;