
import BodyWrapper from "../components/layouts/BodyWrapper";

const Body = () => {
    return (
        <div class="main-content">
            <section className="section">
                <div class="section-header">
                    <h1>Schedule Meeting</h1>
                </div>

                <div class="section-body">
                    <h2 class="section-title">Schedule meeting with a mentor</h2>
                    {/* <p class="section-lead">You're making progress. Keep it up!</p> */}
                    
                    <div class="row">
                        <div class="col-12 col-sm-8">
                            <div class="card">
                                <div class="card-header">
                                    <h4>Appointment Form</h4>
                                </div>
                                <form action="">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label>How important is the meeting</label>
                                            <select class="form-control">
                                                <option>Averagely important</option>
                                                <option>Not really important</option>
                                                <option>Very important</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>Why are you booking a meeting?</label>
                                            <textarea class="form-control"></textarea>
                                        </div>
                                        <div class="form-group">
                                            <label>Select Reason Category</label>
                                            <select class="form-control">
                                                <option>Code issue</option>
                                                <option>Confusing Project or Challenge Description</option>
                                                <option>Personal engagement</option>
                                                <option>Others</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>Preferred Meeting medium</label>
                                            <select class="form-control">
                                                <option>Voice call (on WhatsApp or Phone)</option>
                                                <option>Video meeting (on Zoom or Google Meet)</option>
                                                <option>Physical Meeting (For Ibadan Residents only)</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label>Preferred Date & Time 1</label>
                                            <input type="date" class="form-control" />
                                        </div>
                                        <div class="form-group">
                                            <label>Preferred Date & Time 2</label>
                                            <input type="date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="card-footer text-right">
                                        <button class="btn btn-primary mr-1" type="submit">Schedule Meeting</button>
                                        <button class="btn btn-secondary" type="reset">Reset</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const ScheduleMeeting = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default ScheduleMeeting;