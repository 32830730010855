

import BodyWrapper from "../../components/layouts/BodyWrapper";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'


const Body = () => {
    return (
        <div class="main-content">
            <section className="section">
                <div class="section-header">
                    <h1>Create Thread</h1>
                </div>

                <div class="section-body">
                    <h2 class="section-title">Start a discussion: throw a challenge or ask a question brothering you.</h2>
                    {/* <p class="section-lead">You're making progress. Keep it up!</p> */}

                    
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4>Write Your Post</h4>
                                </div>
                                <div class="card-body">
                                    <div class="form-group row mb-4">
                                        <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Title</label>
                                        <div class="col-sm-12 col-md-7">
                                            <input type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="form-group row mb-4">
                                        <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Category</label>
                                        <div class="col-sm-12 col-md-7">
                                            <select class="form-control ">
                                                <option>Challenge</option>
                                                <option>Personal Question</option>
                                                <option>Tech News</option>
                                                <option>Geegstack Information</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row mb-4">
                                        <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Content</label>
                                        <div class="col-sm-12 col-md-7">                
                                            <ReactQuill theme="snow" formats={{toolbar: false}}  placeholder="Thread content..." />
                                        </div>
                                    </div>
                                    <div class="form-group row mb-4">
                                        <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Thumbnail</label>
                                        <div class="col-sm-12 col-md-7">
                                            <div id="image-preview" class="image-preview">
                                                <label for="image-upload" id="image-label">Choose File</label>
                                                <input type="file" name="image" id="image-" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row mb-4">
                                        <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Tags</label>
                                        <div class="col-sm-12 col-md-7">
                                            <input type="text" class="form-control inputtags" placeholder="Separated by comma" />
                                        </div>
                                    </div>
                                    <div class="form-group row mb-4">
                                        <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                                        <div class="col-sm-12 col-md-7">
                                            <button class="btn btn-primary">Create Thread</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const CreateThread = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default CreateThread;