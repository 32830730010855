

import BodyWrapper from "../../components/layouts/BodyWrapper";
import avatar from "../../assets/img/avatar/default.png";
import { Link } from "react-router-dom";

const Body = () => {
    return (
        <div class="main-content">
            <section className="section">
                <Link to={-1} className="goback"><span className="fa fa-arrow-left"></span> Go Back</Link>
                <div class="section-header">
                    <h1>Leaderboard</h1>
                </div>

                <div class="section-body">
                    <h2 class="section-title">Student's Leaderboard</h2>
                    <p class="section-lead">See how you and your mates are doing.</p>

                    <div class="row">
                        <div class="col-12">
                            <div class="card mt-4">
                                <div class="card-header">
                                    <h4>Learners Ranking</h4>
                                </div>
                                <div class="card-body">
                                    <ul class="list-unstyled user-details list-unstyled-border list-unstyled-noborder">
                                        <li class="media">
                                            <img alt="image" class="mr-3 rounded-circle" width="50" src={avatar} />
                                            <div class="media-body">
                                                <div class="media-title"><Link to="/students/123">Abdulrasheed Ibraheem</Link></div>
                                                <div class="text-job text-muted">Fullstack Student</div>
                                            </div>
                                            <div class="media-items">
                                                <div class="media-item">
                                                    <div class="media-value">10</div>
                                                    <div class="media-label">Projects</div>
                                                </div>
                                                <div class="media-item">
                                                    <div class="media-value">25</div>
                                                    <div class="media-label">Challenges</div>
                                                </div>
                                                <div class="media-item">
                                                    <div class="media-value">1,290</div>
                                                    <div class="media-label">Stars</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="media">
                                            <img alt="image" class="mr-3 rounded-circle" width="50" src={avatar} />
                                            <div class="media-body">
                                                <div class="media-title">Olayiwola Abdulrahman</div>
                                                <div class="text-job text-muted">Frontend Student</div>
                                            </div>
                                            <div class="media-items">
                                                <div class="media-item">
                                                    <div class="media-value">9</div>
                                                    <div class="media-label">Projects</div>
                                                </div>
                                                <div class="media-item">
                                                    <div class="media-value">21</div>
                                                    <div class="media-label">Challenges</div>
                                                </div>
                                                <div class="media-item">
                                                    <div class="media-value">1,010</div>
                                                    <div class="media-label">Stars</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="media">
                                            <img alt="image" class="mr-3 rounded-circle" width="50" src={avatar} />
                                            <div class="media-body">
                                                <div class="media-title">Habeeb Ismail</div>
                                                <div class="text-job text-muted">Fullstack Student</div>
                                            </div>
                                            <div class="media-items">
                                                <div class="media-item">
                                                    <div class="media-value">7</div>
                                                    <div class="media-label">Projects</div>
                                                </div>
                                                <div class="media-item">
                                                    <div class="media-value">20</div>
                                                    <div class="media-label">Challenges</div>
                                                </div>
                                                <div class="media-item">
                                                    <div class="media-value">995</div>
                                                    <div class="media-label">Stars</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const Leaderboard = () => {
    return (
        <BodyWrapper children={<Body />}  page={"leaderboard"}/>
    )
}

export default Leaderboard;