import http from "axios";
import Cookies from "js-cookie";

// const serverurl = "http://localhost:4000";
const serverurl = "https://lmsserver.geegstack.academy";

const requestOptions = (datatype = null) => {
    let options = {};
    const token = Cookies.get("gs_student");
    if (token) {
        options = {
            ...options,
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": datatype,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            }
        }
    }
    return options;
}

const response = (res) => res.data;
const request = {
    get: (url) => http.get(`${serverurl}${url}`, requestOptions() ).then(response),
    post: (url, data) => http.post(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    upload: (url, data) => http.post(`${serverurl}${url}`, data, requestOptions("application/x-www-formurlencoded")).then(response),
    put:(url, data)=> http.put(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    delete:(url, data)=> http.delete(`${serverurl}${url}`, data, requestOptions("application/json")).then(response)
}

const General = {
    profile: (id)=> request.get("/students/" + id),
    updateProfile: (data, id)=> request.upload("/students/" + id,  data),
    login: (params)=> request.post("/students/login", params),
    resetPassword: (params)=> request.post("/students/resetPassword", params),
    getNavData: (params)=> request.post("/students/getNavData", params),
    dashboardData: (params)=> request.post("/students/dashboardData", params),
}

const Course = {
    getStudentTechnologies: (params)=> request.post("/courses/getStudentTechnologies", params),
}

const Lecture = {
    introLectures: (params)=> request.post("/lectures/introLectures", params),
    getTechnologyLectures: (params)=> request.post("/lectures/getTechnologyLectures", params),
    lectureDetails: (params)=> request.post("/lectures/lectureDetails", params),
    lectureWatchability: (params)=> request.post("/lectures/lectureWatchability", params),
    markLectureCompleted: (params)=> request.upload("/lectures/markLectureCompleted", params),
    getSubmittedLectures: (params)=> request.post("/lectures/getSubmittedLectures", params),
    askQuestion: (params)=> request.post("/lectures/askQuestion", params),
    getLectureQuestions: (params)=> request.post("/lectures/getLectureQuestions", params),
}

const Challenge = {
    getALlChallenges: (params)=> request.post("/challenges/getALlChallenges", params),
    getTechChallenges: (data)=> request.post("/challenges/getTechChallenges", data),
    challengeDetails: (params)=> request.post("/challenges/challengeDetails", params),
    submitChallenge: (projectId, data)=> request.upload(`/challenges/${projectId}/submit`, data),
    resubmitChallenge: (projectId, data)=> request.upload(`/challenges/${projectId}/resubmit`, data),
}

const Project = {
    getAllProjects: (params)=> request.post("/projects/allProjects", params),
    getTechProjects: (params)=> request.post("/projects/getTechProjects", params),
    projectDetails: (params)=> request.post("/projects/projectDetails", params),
    submitProject: (projectId, data)=> request.upload(`/projects/${projectId}/submit`, data),
    resubmitProject: (projectId, data)=> request.upload(`/projects/${projectId}/resubmit`, data),
}

const Student = {
    registerStudent: (params)=> request.post("/students/register", params),
    getAllStudents: (params)=> request.post("/students/allStudents", params),
}

export {General, Course, Lecture, Challenge, Project, Student, serverurl};