
import { Link, useNavigate, useParams } from "react-router-dom";
import BodyWrapper from "../../components/layouts/BodyWrapper";
import { useEffect, useState } from "react";
import { Challenge, serverurl } from "../../api";
import Toast from "../../components/Toast";
import Swal from "sweetalert2";



const Body = () => {

    const navigate = useNavigate();
    const [challenge, setChallenge] = useState({});
    const [comment, setComment] = useState("");
    const [challengeFile, setChallengeFile] = useState({});

    const challengeID = useParams().id;

    useEffect(() => {
        Challenge.challengeDetails({challengeID}).then(res => {
            if(res.type === "error") {
                return Toast.fire({title: res.message, icon: "error"}).then(() => navigate("/challenges"));
            }
            console.log(res);
            setChallenge(res.challenge);
        }).catch(err => Toast.fire({title: "Network error", icon: "error"}).then(() => navigate("/challenges")))
    }, [])

    const challengeFileChange = (e) => {
        setChallengeFile(e.target.files[0]);
    }

    const submitChallenge = (e) => {
        e.preventDefault();
        const challengeForm = new FormData();
        challengeForm.append("file", challengeFile);
        challengeForm.append("comment", comment);
        
        Challenge.submitChallenge(challengeID, challengeForm)
        .then(res => {
            if (res.type === "error") {
                return Toast.fire({title: res.message, icon: "error"});
            } else if (res.submitted && res.status.toLowerCase() !== "rejected") {
                Swal.fire({
                    icon: "warning",
                    title: "Challenge already submitted.",
                    text: "You have already submitted for this challenge and it " + (res.status.toLowerCase() === "pending" ? " is undergoing review." : " has been approved.") + " Are you sure  you want to resubmit?",
                    showCancelButton: true,
                    confirmButtonText: "Yes, resubmit",
                    cancelButtonColor: "red",
                    cancelButtonText: "No, that's a mistake"
                }).then((res) => {
                    if (res.isConfirmed) {
                        Challenge.resubmitChallenge(challengeID, challengeForm)
                        .then((res) => {
                            if (res.type === "error") {
                                return Toast.fire({title: res.message, icon: "error"});
                            }
                            
                            Swal.fire({
                                icon: "success",
                                title: "Challenge resubmitted successfully.",
                                text: "You will be notified once it is approved by a lecturer."
                            }).then(() => {
                                navigate("/challenges");
                            })

                        })
                        .catch(() => {
                            Toast.fire({icon: "error", title: "Error uploading submission. Go back a step and retry."});
                        })
                    }
                })

            } else {
                Swal.fire({
                    icon: "success",
                    title: "Challenge submitted successfully.",
                    text: "You will be notified once it is approved by a lecturer."
                }).then(() => {
                    navigate("/challenges");
                })
            }
        })
        .catch((err) => {
            console.log(err);
            Toast.fire({icon: "error", title: "Error uploading submission. Go back a step and retry."});
        })
    }

    return (
        <div class="main-content">
            <section className="section">
                <Link to={-1} className="goback"><span className="fa fa-arrow-left"></span> Go Back</Link>
                <div class="section-header">
                    <h1>{challenge?.title}</h1>
                </div>

                <div class="section-body">
                    <h2 class="section-title">Are you ready?</h2>
                    <p class="section-lead">Let's get in!</p>

                    
                    <div class="row">
                        <div class="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Start a new Challenge</h4>
                                </div>
                                <div className="row card-body">
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <img src={serverurl + challenge.picture_url } width="100%" alt="" />
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6">
                                        <h4>Challenge Description</h4>
                                        <p>{challenge?.description}</p>
                                        <div class="card-footer text-md-right">
                                            <a href={serverurl + challenge.file_url} class="btn btn-primary" id="save-btn" download> <i className="fas fa-download"></i> Download starter file</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Challenge Submission</h4>
                                </div>
                                <div className="card-body">
                                    <h6>Upload Your Solution file:</h6>
                                    <form action="" onSubmit={submitChallenge}>
                                        <div class="form-group">
                                            <input type="file" class="form-control" onChange={challengeFileChange} />
                                        </div>
                                        <div class="form-group">
                                            <label htmlFor="comment"><h6>Do you have any comment?</h6></label>
                                            <textarea id="comment"  class="form-control" placeholder="Enter your comments here" onChange={(e) => setComment(e.target.value)}>{comment}</textarea>
                                        </div>
                                        <div class="card-footer text-md-right">
                                            <button class="btn btn-primary" id="save-btn"> <i className="fas fa-upload" onClick={submitChallenge}></i> Submit Solution</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const ChallengeDetails = () => {
    return (
        <BodyWrapper children={<Body />} page={"challenge"} />
    )
}

export default ChallengeDetails;