

import BodyWrapper from "../../components/layouts/BodyWrapper";


const Body = () => {
    return (
        <div class="main-content">
            <section className="section">
                <div class="section-header">
                    <h1>My Course Details</h1>
                </div>

                <div class="section-body">
                    <h2 class="section-title">You're enrolled in Frontend Development (Full time)</h2>
                    <p class="section-lead">Here's what you need to know about your course.</p>
                    
                    <div class="row">
                        <div class="col-12">
                            <div class="col-md-10">
                                <div class="card" id="settings-card">
                                    <div class="card-header flex justify-content-between">
                                        <h4>Your Learning Path</h4>
                                            <button class="btn btn-primary" id="save-btn"><i className="fa fa-download"></i> Download Outline</button>
                                    </div>
                                    <div class="card-body">
                                        <div className="col-12">
                                            <div class="activities">
                                                <div class="activity">
                                                    <div class="activity-icon bg-primary text-white shadow-primary"><i class="fas fa-arrows-alt"></i></div>
                                                    <div class="activity-detail">
                                                        <p className="font-weight-bold">Environment Setup & Introduction to Web Development.</p>
                                                            <span class="text-job text-primary">1 day</span>
                                                    </div>
                                                </div>
                                                <div class="activity">
                                                    <div class="activity-icon bg-primary text-white shadow-primary"><i class="fas fa-arrows-alt"></i></div>
                                                    <div class="activity-detail" style={{minWidth: "250px"}}>
                                                        <p className="font-weight-bold">Creating Web page Markups with HTML</p>
                                                            <span class="text-job text-primary">2 Weeks, 3 days</span>
                                                    </div>
                                                </div>
                                                <div class="activity">
                                                    <div class="activity-icon bg-primary text-white shadow-primary"><i class="fas fa-arrows-alt"></i></div>
                                                    <div class="activity-detail" style={{minWidth: "250px"}}>
                                                        <p className="font-weight-bold">HTML Project</p>
                                                            <span class="text-job text-primary">3 days</span>
                                                    </div>
                                                </div>
                                                <div class="activity">
                                                    <div class="activity-icon bg-primary text-white shadow-primary"><i class="fas fa-arrows-alt"></i></div>
                                                    <div class="activity-detail" style={{minWidth: "250px"}}>
                                                        <p className="font-weight-bold">Styling web pages with CSS</p>
                                                            <span class="text-job text-primary">4 Weeks</span>
                                                    </div>
                                                </div>
                                                <div class="activity">
                                                    <div class="activity-icon bg-primary text-white shadow-primary"><i class="fas fa-arrows-alt"></i></div>
                                                    <div class="activity-detail" style={{minWidth: "250px"}}>
                                                        <p className="font-weight-bold">CSS Projects</p>
                                                            <span class="text-job text-primary">4 days</span>
                                                    </div>
                                                </div>
                                                <div class="activity">
                                                    <div class="activity-icon bg-primary text-white shadow-primary"><i class="fas fa-arrows-alt"></i></div>
                                                    <div class="activity-detail" style={{minWidth: "250px"}}>
                                                        <p className="font-weight-bold">Managing projects versions with Git & Management and collaboration with Github</p>
                                                            <span class="text-job text-primary">1 day</span>
                                                    </div>
                                                </div>
                                                <div class="activity">
                                                    <div class="activity-icon bg-primary text-white shadow-primary"><i class="fas fa-arrows-alt"></i></div>
                                                    <div class="activity-detail" style={{minWidth: "250px"}}>
                                                        <p className="font-weight-bold">JavaScript Programming Deep Dive</p>
                                                            <span class="text-job text-primary">5 Weeks</span>
                                                    </div>
                                                </div>
                                                <div class="activity">
                                                    <div class="activity-icon bg-primary text-white shadow-primary"><i class="fas fa-arrows-alt"></i></div>
                                                    <div class="activity-detail" style={{minWidth: "250px"}}>
                                                        <p className="font-weight-bold">JavaScript Projects</p>
                                                            <span class="text-job text-primary">1 Week</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-md-right">
                                            <button class="btn btn-primary" id="save-btn">Mark as completed</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

const CourseDetails = () => {
    return (
        <BodyWrapper children={<Body />} />
    )
}

export default CourseDetails;