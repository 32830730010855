
import './App.css';
import "./assets/css/style.min.css";
import "./assets/css/components.min.css";

import {Routes, Route, BrowserRouter} from "react-router-dom";


import Dashboard from "./pages/Profile/Dashboard";
import ErrorPage from "./pages/ErrorPage";
import Modules from "./pages/Lecture/Lectures";
import Module from "./pages/Lecture/Module";
import Forum from "./pages/Thread/Forum";
import CreateThread from "./pages/Thread/CreateThread";
import Thread from './pages/Thread/ThreadDetails';
import Challenges from './pages/Challenge/Challenges';
import ChallengeDetails from './pages/Challenge/ChallengeDetails';
import Projects from './pages/Project/Projects';
import ProjectDetails from './pages/Project/ProjectDetails';
import ScheduleMeeting from './pages/ScheduleMeeting';
import Quizzes from './pages/Quiz/Quizes';
import MyProfile from './pages/Profile/MyProfile';
import Leaderboard from './pages/Profile/Leaderboard';
import CourseDetails from './pages/Course/CourseDetails';
import StudentProfile from './pages/Profile/StudentProfile';
import Login from './pages/Login';
import MySubmissions from './pages/Profile/MySubmissions';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Dashboard />} />
        <Route path='/login' element={<Login />} />

        <Route path='/lectures' element={<Modules />} />
        <Route path='/lectures/:id' element={<Module />} />

        <Route path='/forum' element={<Forum />} />
        <Route path='/create-thread' element={<CreateThread />} />
        <Route path='/threads/:id' element={<Thread />} />

        <Route path='/challenges' element={<Challenges />} />
        <Route path='/challenges/:id' element={<ChallengeDetails />} />

        <Route path='/projects' element={<Projects />} />
        <Route path='/projects/:id' element={<ProjectDetails />} />

        <Route path='/schedule' element={<ScheduleMeeting />} />
        <Route path='/quizzes' element={<Quizzes />} />

        <Route path='/profile' element={<MyProfile />} />
        <Route path='/leaderboard' element={<Leaderboard />} />
        <Route path='/my-submissions' element={<MySubmissions />} />
        <Route path='/course-details' element={<CourseDetails />} />
        <Route path='/students/123' element={<StudentProfile />} />


        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
