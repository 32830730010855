

import { Link } from "react-router-dom";
import BodyWrapper from "../../components/layouts/BodyWrapper";

const Body = () => {
    return (
        <div class="main-content">
            <section className="section">
                <div class="row">
                    <div class="col-12">   
                        <div class="page-error">
                            <div class="page-inner">
                                <h1>Soon...</h1>
                                <div class="page-description">
                                    Don't fret. The Quiz pages are under engineering. You will be notified once they're up!
                                </div>
                                <div class="page-search">
                                    <div class="mt-3"><Link to="/">Back to Dashboard</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </section>
        </div>
    )
}

const Quizzes = () => {
    return (
        <BodyWrapper children={<Body />} page={"quizzes"} />
    )
}

export default Quizzes;