import { useEffect } from "react";
import { useState } from "react";
import {useParams, useNavigate, Link } from "react-router-dom";
import { Project } from "../../api";
import BodyWrapper from "../../components/layouts/BodyWrapper";
import Toast from "../../components/Toast";
import Swal from "sweetalert2";

const Body = () => {

    const navigate = useNavigate();
    const [project, setProject] = useState({});
    const [comment, setComment] = useState("");
    const [projectFile, setProjectFile] = useState({});

    const projectID = useParams().id;

    useEffect(() => {
        Project.projectDetails({projectID}).then(res => {
            if(res.type === "error") {
                return Toast.fire({title: res.message, icon: "error"}).then(() => navigate("/projects"));
            }
            setProject(res.project);
        }).catch(err => Toast.fire({title: "Network error", icon: "error"}).then(() => navigate("/projects")))
    }, [])

    const projectFileChange = (e) => {
        setProjectFile(e.target.files[0]);
    }

    const submitProject = (e) => {
        e.preventDefault();
        const projectForm = new FormData();
        projectForm.append("file", projectFile);
        projectForm.append("comment", comment);
        
        Project.submitProject(projectID, projectForm)
        .then(res => {
            if (res.type === "error") {
                return Toast.fire({title: res.message, icon: "error"});
            } else if (res.submitted & res.status.toLowerCase() !== "rejected") {
                Swal.fire({
                    icon: "warning",
                    title: "Project already submitted.",
                    text: "You have already submitted for this project and it " + (res.status.toLowerCase() === "pending" ? " is undergoing review." : " has been approved.") + " Are you sure  you want to resubmit?",
                    showCancelButton: true,
                    confirmButtonText: "Yes, resubmit",
                    cancelButtonColor: "red",
                    cancelButtonText: "No, that's a mistake"
                }).then((res) => {
                    if (res.isConfirmed) {
                        Project.resubmitProject(projectID, projectForm)
                        .then((res) => {
                            if (res.type === "error") {
                                return Toast.fire({title: res.message, icon: "error"});
                            }
                            
                            Swal.fire({
                                icon: "success",
                                title: "Project resubmitted successfully.",
                                text: "You will be notified once it is approved by a lecturer."
                            }).then(() => {
                                navigate("/projects");
                            })

                        })
                        .catch(() => {
                            Toast.fire({icon: "error", title: "Error uploading submission. Go back a step and retry."});
                        })
                    }
                })

            } else {
                Swal.fire({
                    icon: "success",
                    title: "Project submitted successfully.",
                    text: "You will be notified once it is approved by a lecturer."
                }).then(() => {
                    navigate("/projects");
                })
            }
        })
        .catch(() => {
            Toast.fire({icon: "error", title: "Error uploading submission. Go back a step and retry."});
        })
    }

    return (
        <div className="main-content">
            <section className="section">
                <Link to={-1} className="goback"><span className="fa fa-arrow-left"></span> Go Back</Link>
                <div className="section-header">
                    <h1>{project.title}</h1>
                </div>

                <div className="section-body">
                    <h2 className="section-title">Are you ready?</h2>
                    <p className="section-lead">Don't panic it's a very simple project. You already have all that you need to break it.</p>
                    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Start this Project.</h4>
                                </div>
                                <div className="row card-body">
                                    <div className="col-12 col-sm-6 col-md-6">
                                        <img src={"http://localhost:4000" + project.picture_url} width="100%" alt="" />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6">
                                        <h4>Project Description</h4>
                                        <p>{project.description}</p>

                                        <p>
                                            <b>Tips:</b> Revise background, float and fonts.
                                        </p>
                                        <div className="card-footer text-md-right">
                                            <a href={"http://localhost:4000" + project.file_url} download={"http://localhost:4000" + project.file_url} className="btn btn-primary" id="save-btn"> <i className="fas fa-download"></i> Download starter file</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Project Submission</h4>
                                </div>
                                <div className="card-body">
                                    <h6>Upload Your Solution file:</h6>
                                    <form action="">
                                        <div className="form-group">
                                            <input type="file" className="form-control" onChange={projectFileChange} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="comment"><h6>Do you have any comment?</h6></label>
                                            <textarea id="comment" className="form-control" placeholder="Enter your comments here" onChange={e => setComment(e.target.value)}>{comment}</textarea>
                                        </div>
                                        <div className="card-footer text-md-right">
                                            <button className="btn btn-primary" id="save-btn" onClick={submitProject}> <i className="fas fa-upload"></i> Submit Solution</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

const ProjectDetails = () => {
    return (
        <BodyWrapper children={<Body />} page={"project"} />
    )
}

export default ProjectDetails;