import { Link, useNavigate } from "react-router-dom";
import { Lecture } from "../api";
import Toast from "./Toast";
import Swal from "sweetalert2";
import { useState } from "react";
import { useEffect } from "react";

const numToStr = (num) => {
    return num < 10 ? "0" + num : num;
}

const ModuleList = ({lectures, course}) => {
    const navigate = useNavigate();
    const [submittedLectures, setSubmittedLectures] = useState([]);

    useEffect(() => {
        Lecture.getSubmittedLectures().then((res) => {
            if(res.type === "error") {
                return Toast.fire({icon: "error", title: res.message});
            }
            setSubmittedLectures(res.lectures);
        }).catch(err => {
            console.log(err);
        })
    }, [])

    const watchLecture = (id) => {
        Lecture.lectureWatchability({lectureID: id}).then(res => {
            if(res.type === "error") {
                return Toast.fire({icon: "error", title: res.message});
            }
            navigate("/lectures/" + id);
        }).catch(err => {
            console.log(err);
            Swal.fire({icon: "error", title: "Lecture error", text: "Lecture is currently unavailable. Check your network connection and reload the page. If error persists, contact an Instructor immediately."});
        })
    }

    return (
    <div className="card">
        <div className="card-header">
            <h4>{course} Modules. Total: {lectures.length}</h4>
            <div className="card-header-action">
                
            </div>
        </div>
        <div className="card-body p-0">
            <div className="table-responsive">
                <table className="table table-striped v_center" id="sortable-table">
                    <thead>
                        <tr>
                        <th>Description</th>
                        <th>Duration</th>
                        <th>Status</th>
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lectures.length > 0 ? lectures.map(lecture => {
                                return (
                                    <tr key={lecture.id}>
                                        <td>{lecture.title}</td>
                                        <td>{numToStr(Math.floor(lecture.duration/3600))}:{numToStr(Math.floor(lecture.duration/60))}:{numToStr(lecture.duration%60)}</td>
                                        <td>
                                            {
                                                submittedLectures.some(lect => lect.lecture == lecture.id) ?
                                                <div className="badge badge-success">Watched</div>
                                                :
                                                <div className="badge badge-warning">Pending</div>
                                            }
                                        </td>
                                        <td><a href="#" onClick={() => watchLecture(lecture.id)} className="btn btn-primary btn-sm"><span className="fas fa-play"></span>&nbsp; Watch </a> &nbsp;
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                               <td colSpan={4}>No Lecture has been added for {course} yet.</td> 
                            </tr>
                            
                        }

                        {/* <tr>
                            <td>Backup database</td>
                            <td>00:23:02</td>
                            <td><div className="badge badge-warning">In Progress</div></td>
                            <td><Link to="/lectures/123" className="btn btn-primary"><span className="fas fa-play"></span> &nbsp; Watch</Link></td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    )
}

export default ModuleList;