
import { useState, useEffect } from "react";
import logo from "../assets/img/geegstack/logo.png";
import {Link, useNavigate} from "react-router-dom";
import { General, Student } from "../api";
import Cookies from "js-cookie";
import Toast from "../components/Toast";

const Login = () => {
    const admin = Cookies.get("gs_student");
    const navigate = useNavigate();
    const [form, setForm] = useState({email: "", password: ""});
    const [passForm, setPassForm] = useState({newPassword: "", confirmPassword: ""});
    const [settingPassword, setSettingPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState("");

    const inputChange = (e) => {
        const name = e.target.name;
        if(name === "email" || name === "password" ) {
            setForm({...form, [name]: e.target.value});
        } else {
            setPassForm({...passForm, [name]: e.target.value});
        }
    }

    useEffect(() => {
        if(admin && admin.length > 1) {
            navigate("/");
        }
    },[])

    const Login = (e) => {
        e.preventDefault();
        setLoading(true);
        if(!form.email || !form.password) {
            return Toast.fire({icon: "error", title: "Email and Password are required"});
        }

        General.login(form).then((res) => {
            setLoading(false);
            if(res.type == "error") {
                return Toast.fire({icon: "error", title: res.message});
            }
            if(res.newStudent) {
                Toast.fire({icon: "success", title: "Welcome to Geegstack LMS! Change your password to Login"}).then(() => {
                    setSettingPassword(true);
                    setToken(res.token);
                }) 
            } else {
                Toast.fire({icon: "success", title :"Logged in"}).then(() => navigate("/"));
                Cookies.set("gs_student", res.token);
            }
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"});
        })
    }

    const resetPassword = e => {
        e.preventDefault();
        const passChangeForm = { 
            email: form.email, 
            password: passForm.newPassword, 
            confirmPassword: passForm.confirmPassword, 
            token: form.password
        };
        
        General.resetPassword(passChangeForm).then(res => {
            if(res.type === "error") {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title :"Logged in"}).then(() => {
                Cookies.set("gs_student", token);
                navigate("/")
            } );
        }).catch(() => Toast.fire({icon: "error", title: "Network error"}))
    }

    return (
        <div id="app">
            <section className="section">
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                            <div className="login-brand">
                                <img src={logo} alt="logo" width="200" />
                            </div>
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h4>{settingPassword ? "Change Password" : "Login"}</h4>
                                </div>
                                <div className="card-body">
                                    {
                                    settingPassword ? 
                                    <form method="POST" action="#" className="needs-validation" noValidate="" onSubmit={resetPassword}>
                                        <div className="form-group">
                                            <label htmlFor="newPassword">New Password</label>
                                            <input 
                                            id="newPassword" 
                                            type="password" 
                                            className="form-control" 
                                            name="newPassword" tabIndex="1" 
                                            required
                                            onChange={inputChange}
                                            value={passForm.newPassword} />
                                            <div className="invalid-feedback">
                                                Provide new Password
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Confirm New Password</label>
                                            <input 
                                            id="confirmPassword" 
                                            type="password" 
                                            className="form-control" 
                                            name="confirmPassword" 
                                            tabIndex="2" required 
                                            onChange={inputChange}
                                            value={passForm.confirmPassword}
                                            />
                                            <div className="invalid-feedback">
                                                please fill in your confirm password
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary btn-lg btn-block" tabIndex="4">
                                            Change Password
                                            </button>
                                        </div>
                                    </form>
                                        :
                                    <form method="POST" action="#" className="needs-validation" noValidate="" onSubmit={Login}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input 
                                            id="email" 
                                            type="email" 
                                            className="form-control" 
                                            name="email" tabIndex="1" 
                                            required
                                            onChange={inputChange}
                                            value={form.email} />
                                            <div className="invalid-feedback">
                                                Please fill in your email
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="d-block">
                                                <label htmlFor="password" className="control-label">Password</label>
                                                <div className="float-right">
                                                    <a href="auth-forgot-password.html" className="text-small">
                                                    Forgot Password?
                                                    </a>
                                                </div>
                                            </div>
                                            <input 
                                            id="password" 
                                            type="password" 
                                            className="form-control" 
                                            name="password" 
                                            tabIndex="2" required 
                                            onChange={inputChange}
                                            value={form.password}
                                            />
                                            <div className="invalid-feedback">
                                                please fill in your password
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary btn-lg btn-block" tabIndex="4">
                                            {loading ? "Logging in.." : "Login"}
                                            </button>
                                        </div>
                                    </form>
                                    }
                                </div>
                            </div>
                            <div className="simple-footer">
                                Copyright &copy; Geegstack Academy 2022
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Login;